import {
  NineNavigation,
  NineOverlay,
  NineProgressBar
} from "@9amhealth/wcl/generated/react";
import { useBloc } from "@blac/react";
import styled from "@emotion/styled";
import type { FC } from "react";
import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";

import SignupCubit from "src/state/SignupCubit/SignupCubit";
import type { SignupCustomPageParameters } from "src/ui/components/SignupCustomContent/SignupCustom.types";
import SignupCustomContent from "src/ui/components/SignupCustomContent/SignupCustomContent";
import SignupCustomBloc from "src/ui/components/SignupCustomContent/state/SignupCustomBloc";
import Translate from "src/ui/components/Translate/Translate";

const PageWrap = styled.div``;

const SignupCustomPage: FC = () => {
  useEffect(() => {
    SignupCubit.loadSupportChat();
    return SignupCubit.removeSupportChat;
  }, []);
  const parameters = useParams<SignupCustomPageParameters>();
  const queryStrings = new URLSearchParams(window.location.search);
  const isDemoFunnel = window.location.pathname.startsWith("/demo/signup");
  const [
    ,
    {
      coBrandedLogo,
      coBrandedLogoSize,
      customLogo,
      campaignSteps,
      currentStep,
      checkForOtherFunnelSubscriptions
    }
  ] = useBloc(SignupCustomBloc, {
    props: {
      parameters,
      options: {
        preview: queryStrings.get("preview") === "true",
        demo: isDemoFunnel
      }
    }
  });
  const progressPercent = useMemo(() => {
    const totalSteps = campaignSteps ?? [];
    const currentStepIndex =
      totalSteps.findIndex((step) => step === currentStep) + 1;

    return (currentStepIndex / totalSteps.length) * 100;
  }, [parameters.campaign, parameters.step]);

  useEffect(() => {
    void checkForOtherFunnelSubscriptions();
  }, []);

  return (
    <PageWrap>
      <NineNavigation
        app
        variant="stepper"
        style={
          {
            background: `var(--color-cream)`,
            "--nav-partner-logo-h":
              coBrandedLogoSize === "large" ? "80px" : "43px",
            "--nav-partner-logo-w":
              coBrandedLogoSize === "large" ? "140px" : "120px"
          } as React.CSSProperties
        }
        partnerLogo={
          coBrandedLogo
            ? coBrandedLogo + "?mock-type=.png&w=200&format=auto"
            : ""
        }
        customLogo={customLogo ?? ""}
      >
        <NineProgressBar
          slot="progress"
          progress={`${progressPercent}%`}
        ></NineProgressBar>
        <NineOverlay slot="help" variant="toast">
          <div slot="trigger" className="strong">
            <Translate msg="help.question" />
          </div>
          <div slot="content">
            <h5 className="strong m0">
              <Translate msg="help.questions" />
            </h5>
            <p className="lauf-text">
              <Translate msg="help.phoneSupport" />{" "}
              <a href="tel:2029329958">(202) 932-9958</a>
              <br />
              <Translate msg="help.emailSupport" />{" "}
              <a href="mailto:support@join9am.com">support@join9am.com</a>
            </p>
          </div>
        </NineOverlay>
      </NineNavigation>

      <SignupCustomContent />
    </PageWrap>
  );
};

export default SignupCustomPage;
