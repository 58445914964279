import type { FC } from "react";
import React from "react";
import FadeWrap from "src/ui/components/FadeWrap/FadeWrap";
import type {
  SignupCustomStep,
  SignupCustomStepInfoPage
} from "src/ui/components/SignupCustomContent/SignupCustom.types";
import { SignupCustomStepType } from "src/ui/components/SignupCustomContent/SignupCustom.types";

const SignupCustomStepInfoPageContent: FC<{
  step: SignupCustomStepInfoPage;
}> = ({ step }) => {
  return (
    <FadeWrap>
      <>{step.render()}</>
    </FadeWrap>
  );
};

const SignupCustomStepInfoPageCustomRender: FC<{
  step: SignupCustomStep;
}> = ({ step }) => {
  const isSuccessPage = step.type === SignupCustomStepType.InfoPage;

  return isSuccessPage ? <SignupCustomStepInfoPageContent step={step} /> : null;
};

export default SignupCustomStepInfoPageCustomRender;
