import type { UserAddressApiDto } from "@9amhealth/openapi";
import styled from "@emotion/styled";
import type { FC } from "react";
import React from "react";
import Loader, { LoadingIndicator } from "src/ui/components/Loader/Loader";
import ShippingForm from "src/ui/components/ShippingForm/ShippingForm";
import type {
  SignupCustomStep,
  SignupCustomStepShippingAddress
} from "src/ui/components/SignupCustomContent/SignupCustom.types";
import { SignupCustomStepType } from "src/ui/components/SignupCustomContent/SignupCustom.types";
import { InputFocusVirtualKeyboardPadding } from "../../StyledComponents/InputFocusVirtualKeyboardPadding";

const LoadingForm = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 624px;

  @media screen and (min-width: 960px) {
    height: 464px;
  }
`;

const SignupCustomStepShippingAddressContent: FC<{
  step: SignupCustomStepShippingAddress;
  onStepCompleted: (step: SignupCustomStep) => Promise<boolean> | boolean;
}> = ({ step, onStepCompleted }) => {
  const [loading, setLoading] = React.useState(false);

  const handleSuccess = async (data?: UserAddressApiDto) => {
    if (loading) return;

    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 4000);

    if (data?.street) {
      await onStepCompleted(step);
    }
  };

  return (
    <>
      <Loader active={loading} grow fullPage>
        <nine-center>
          <nine-spacer s="md"></nine-spacer>
          <nine-heading>
            <h3>
              {step.shippingFormPageProps?.title ?? "Your shipment information"}
            </h3>
            <nine-spacer s="xs"></nine-spacer>
            <p className="m0 color-c-80">
              {step.shippingFormPageProps?.subtitle ??
                "Please enter your shipping address"}
            </p>
          </nine-heading>

          <nine-spacer s="xl"></nine-spacer>

          <InputFocusVirtualKeyboardPadding>
            <ShippingForm
              onSuccess={handleSuccess}
              onSubmitStart={() => setLoading(true)}
              onValidationEnd={() => setLoading(false)}
              loadingElement={
                <LoadingForm>
                  <LoadingIndicator />
                </LoadingForm>
              }
            />
          </InputFocusVirtualKeyboardPadding>
          <nine-spacer s="xl"></nine-spacer>
          <nine-spacer s="xl"></nine-spacer>
        </nine-center>
      </Loader>
    </>
  );
};

const SignupCustomStepShippingAddressRender: FC<{
  step: SignupCustomStep;
  onStepCompleted: (step: SignupCustomStep) => Promise<boolean> | boolean;
}> = ({ step, onStepCompleted }) => {
  const isShippingStep = step.type === SignupCustomStepType.ShippingAddress;
  return isShippingStep ? (
    <SignupCustomStepShippingAddressContent
      step={step}
      onStepCompleted={onStepCompleted}
    />
  ) : (
    <></>
  );
};

export default SignupCustomStepShippingAddressRender;
