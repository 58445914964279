import type { FC } from "react";
import React from "react";
import FadeWrap from "src/ui/components/FadeWrap/FadeWrap";
import type { SignupCustomStepProps } from "src/ui/components/SignupCustomContent/RenderSignupCustomStep";
import { SignupCustomStepType } from "src/ui/components/SignupCustomContent/SignupCustom.types";
import ConsentForm from "../../ConsentForm/ConsentForm";
import styled from "@emotion/styled";
import { APP_BREAKPOINT } from "src/constants/layout";

const CenterForm = styled.div`
  display: flex;
  align-items: flex-start;
  height: 100%;
  width: min(100%, 500px);
  margin: 0 auto;
  padding: 1em;

  @media screen and (min-width: ${APP_BREAKPOINT}px) {
    margin: 5rem auto 0;
  }

  .button-position {
    justify-content: center;
    margin: 2.5rem 0;

    @media screen and (min-width: ${APP_BREAKPOINT}px) {
      margin: 3rem 0;
    }
  }
`;

const SignupCustomStepAuthConsentContent: FC<SignupCustomStepProps> = ({
  step,
  onStepCompleted
}) => {
  const handleSuccess = async () => {
    await onStepCompleted(step);
  };

  return (
    <FadeWrap>
      <CenterForm>
        <ConsentForm onSuccess={handleSuccess} isRegistering={true} />
      </CenterForm>
    </FadeWrap>
  );
};

const SignupCustomStepAuthConsent: FC<SignupCustomStepProps> = (props) => {
  const isAuthPage = props.step.type === SignupCustomStepType.Consents;
  return isAuthPage ? (
    <SignupCustomStepAuthConsentContent
      onStepCompleted={props.onStepCompleted}
      step={props.step}
    />
  ) : (
    <></>
  );
};

export default SignupCustomStepAuthConsent;
