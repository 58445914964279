import type { FC } from "react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FadeWrap from "src/ui/components/FadeWrap/FadeWrap";
import type {
  SignupCustomStep,
  SignupCustomStepSuccessPage
} from "src/ui/components/SignupCustomContent/SignupCustom.types";
import { SignupCustomStepType } from "src/ui/components/SignupCustomContent/SignupCustom.types";
import SignupSuccessPage from "src/ui/components/SignupCustomContent/SignupSuccessPage";

const SignupCustomStepSuccessContent: FC<{
  step: SignupCustomStepSuccessPage;
}> = ({ step }) => {
  const { successPageProps } = step;
  const navigate = useNavigate();

  useEffect(() => {
    if (step.action) {
      step.action(navigate);
    }
  }, [step.action]);

  return (
    <FadeWrap>
      <SignupSuccessPage {...successPageProps} />
    </FadeWrap>
  );
};

const SignupCustomStepSuccess: FC<{
  step: SignupCustomStep;
}> = ({ step }) => {
  const isSuccessPage = step.type === SignupCustomStepType.SuccessPage;
  return isSuccessPage ? <SignupCustomStepSuccessContent step={step} /> : null;
};

export default SignupCustomStepSuccess;
