import type { FC } from "react";
import React from "react";
import FadeWrap from "src/ui/components/FadeWrap/FadeWrap";
import Loader from "src/ui/components/Loader/Loader";
import type { SignupCustomStep } from "src/ui/components/SignupCustomContent/SignupCustom.types";
import SignupCustomStepAuthentication from "src/ui/components/SignupCustomContent/steps/SignupCustomStepAuthentication";
import SignupCustomStepInfoPageCustomRender from "src/ui/components/SignupCustomContent/steps/SignupCustomStepInfoPageCustomRender";
import SignupCustomStepQuestionnaire from "src/ui/components/SignupCustomContent/steps/SignupCustomStepQuestionnaire";
import SignupCustomStepShippingAddressRender from "src/ui/components/SignupCustomContent/steps/SignupCustomStepShippingAddressRender";
import SignupCustomStepSuccess from "src/ui/components/SignupCustomContent/steps/SignupCustomStepSuccess";
import SignupCustomStepAction from "./steps/SignupCustomStepAction";
import type FunnelKey from "src/constants/funnelKey";
import SignupCustomStepAuthConsent from "./steps/SignupCustomStepAuthConsent";

export interface SignupCustomStepProps {
  step: SignupCustomStep;
  onStepCompleted: (step: SignupCustomStep) => Promise<boolean> | boolean;
  funnelKey?: FunnelKey | string;
}

const RenderSignupCustomStep: FC<{
  step: SignupCustomStep | undefined;
  onStepCompleted: (
    step: SignupCustomStep,
    data?: unknown
  ) => Promise<boolean> | boolean;
  funnelKey?: FunnelKey | string;
}> = ({ step, onStepCompleted, funnelKey }) => {
  const isLoading = !step;

  const stepProps: SignupCustomStepProps | undefined = step
    ? { step, onStepCompleted }
    : undefined;

  if (stepProps) {
    stepProps.funnelKey = funnelKey;
  }

  return (
    <>
      {isLoading && <Loader fixed fullPage />}
      {!isLoading && stepProps && (
        <FadeWrap>
          <SignupCustomStepQuestionnaire {...stepProps} />
          <SignupCustomStepAuthentication {...stepProps} />
          <SignupCustomStepAuthConsent {...stepProps} />
          <SignupCustomStepShippingAddressRender {...stepProps} />
          <SignupCustomStepSuccess {...stepProps} />
          <SignupCustomStepInfoPageCustomRender {...stepProps} />
          <SignupCustomStepAction {...stepProps} />
        </FadeWrap>
      )}
    </>
  );
};

export default RenderSignupCustomStep;
